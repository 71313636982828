import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ExtendedOrderType } from '@orders/enums';
import { OrdersListQueryParams } from '@shared/models/printing';
import { Location } from '@angular/common';
import { EnvironmentName } from '@core/enums/environment-name.enum';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private inventoryRoute: string[] = ['/inventory'];
	private calculateReorderRoute: string[] = ['/admin/calculate-reorder'];
	private scanSessionsRoute: string[] = ['/scan-sessions'];
	private ordersRoute: string[] = ['/orders'];
	private orderEditRoute: string[] = ['/orders/edit'];
	private orderAddRoute: string[] = ['/orders/add'];
	private printingRoute: string[] = ['/printing'];
	private productsRoute: string[] = ['/products'];
	private productEditRoute: string[] = ['/products/edit'];
	private locationsRoute: string[] = ['/locations'];
	private settingsRoute: string[] = ['/settings'];
	private addPrintJobRoute: string[] = ['/printing/add'];
	private reprintPrintJobRoute: string[] = ['/printing/reprint'];
	private labelLayoutsRoute: string[] = ['/manager/label-layouts'];
	private labelLayoutAddRoute: string[] = ['/manager/label-layouts/add'];
	private labelLayoutEditRoute: string[] = ['/manager/label-layouts/edit'];
	private orderCreatePrintJobRoute: string[] = ['/printing/orders/create'];
	private emailHistoryRoute: string[] = ['/admin/email/history'];
	private tiersListRoute: string[] = ['/tiers'];
	private forbiddenRoute: string = '/forbidden';
	private orderImportRoute: string[] = ['/orders/import'];
	private emailRoute: string[] = ['/admin/email/history'];
	private adminPrintersRoute: string[] = ['/admin/print-stations/'];
	private adminStationSettingsRoute: string[] = ['/admin/print-stations/station-settings'];
	private adminPrinterSettingsRoute: string[] = ['/admin/print-stations/printer-settings'];
	private managerPrintersRoute: string[] = ['/manager/print-stations/'];
	private adminHandheldReadersRoute: string[] = ['/admin/handheld-readers'];
	private handheldReadersRoute: string[] = ['/handheld-readers'];
	private managerStationSettingsRoute: string[] = ['/manager/print-stations/station-settings'];
	private managerPrinterSettingsRoute: string[] = ['/manager/print-stations/printer-settings'];
	private printerSettingsRoute: string[] = ['/printing/printer-settings'];
	private adminCustomersRoute: string[] = ['/admin/customers'];
	private adminEditCustomerRoute: string[] = ['/admin/customers/edit'];
	private adminAddCustomerRoute: string[] = ['/admin/customers/add'];
	private managerCustomerProfileRoute: string[] = ['/manager/customer-profile'];

	private adminUserListRoute: string[] = ['/admin/users'];
	private adminAddUserRoute: string[] = ['/admin/users/add'];
	private adminEditUserRoute: string[] = ['/admin/users/edit'];
	private managerUsersRoute: string[] = ['/manager/users'];
	private managerAddUserRoute: string[] = ['/manager/users/add'];
	private managerEditUserRoute: string[] = ['/manager/users/edit'];

	//Temp (Need for tree products catalog)
	private adminCustomers2Route: string[] = ['/admin/customers/customers2'];
	private adminEditCustomer2Route: string[] = ['/admin/customers/edit2'];
	//***

	constructor(
		private router: Router,
		private location: Location,
	) {}

	public getAddPrintJobRoute(): string[] {
		return this.addPrintJobRoute;
	}

	public getReprintPrintJobRoute(id: number): string[] {
		return [...this.reprintPrintJobRoute, id.toString()];
	}

	public getInventoryRoute(): string[] {
		return this.inventoryRoute;
	}

	public getCalculateReorderRoute(): string[] {
		return this.calculateReorderRoute;
	}

	public getScanSessionsRoute(): string[] {
		return this.scanSessionsRoute;
	}

	public getOrdersRoute(type: string): string[] {
		return [...this.ordersRoute, type.toLowerCase()];
	}

	public getOrderAddRoute(type: string): string[] {
		return [...this.orderAddRoute, type];
	}

	public getOrderEditRoute(id: number): string[] {
		return [...this.orderEditRoute, id.toString()];
	}

	public getPrintingRoute(): string[] {
		return this.printingRoute;
	}

	public getOldPrintingUrl(vendorId: number): string {
		return `${environment.oldSiteBaseAddress}/${vendorId}/PrintJobs/Index`;
	}

	public getAdminPrintersRoute(): string[] {
		return this.adminPrintersRoute;
	}

	public getAdminStationSettingsRoute(id: number): string[] {
		return [...this.adminStationSettingsRoute, id.toString()];
	}

	public getAdminPrinterSettingsRoute(id: number): string[] {
		return [...this.adminPrinterSettingsRoute, id.toString()];
	}

	public getManagerPrintersRoute(): string[] {
		return this.managerPrintersRoute;
	}

	public getManagerStationSettingsRoute(id: number): string[] {
		return [...this.managerStationSettingsRoute, id.toString()];
	}

	public getManagerPrinterSettingsRoute(id: number): string[] {
		return [...this.managerPrinterSettingsRoute, id.toString()];
	}

	public getPrinterSettingsRoute(id: number): string[] {
		return [...this.printerSettingsRoute, id.toString()];
	}

	public getLabelLayoutEditRoute(id: number): string[] {
		return [...this.labelLayoutEditRoute, id.toString()];
	}

	public getProductsRoute(): string[] {
		return this.productsRoute;
	}

	public toProductsRoute() {
		this.router.navigate(this.productsRoute);
	}

	public getProductEditRoute(id: string): string[] {
		return [...this.productEditRoute, id];
	}

	public toProductEditRoute(id: string) {
		this.router.navigate([...this.productEditRoute, id]);
	}

	public getLocationsRoute(): string[] {
		return this.locationsRoute;
	}

	public getSettingsRoute(): string[] {
		return this.settingsRoute;
	}

	public getLabelLayoutAddRoute() {
		return this.labelLayoutAddRoute;
	}

	public getCustomersListRoute() {
		return this.adminCustomersRoute;
	}
	public getAdminHandheldReadersListRoute() {
		return this.adminHandheldReadersRoute;
	}

	public getHandheldReadersListRoute() {
		return this.handheldReadersRoute;
	}

	//Temp (Need for tree products catalog)
	public getCustomersList2Route() {
		return this.adminCustomers2Route;
	}

	public toEditCustomer2Route(id: number) {
		this.router.navigate([...this.adminEditCustomer2Route, id.toString()]);
	}

	public toCustomers2Route(): void {
		this.router.navigate(this.adminCustomers2Route);
	}
	//***

	public getEditCustomerRoute(id: number): string[] {
		return [...this.adminEditCustomerRoute, id.toString()];
	}
	public getCustomerProfileRoute(): string[] {
		return this.managerCustomerProfileRoute;
	}
	public toCustomerProfileRoute() {
		this.router.navigate(this.managerCustomerProfileRoute);
	}

	public getCreateCustomerRoute() {
		return this.adminAddCustomerRoute;
	}

	public toEditCustomerRoute(id: number) {
		this.router.navigate([...this.adminEditCustomerRoute, id.toString()]);
	}

	public toPrintingRoute(queryParams?: OrdersListQueryParams): void {
		let params = undefined;
		if (queryParams) {
			params = {
				queryParams: { ...queryParams },
			};
		}
		this.router.navigate(this.printingRoute, params);
	}

	public toEditOrderRoute(id: number): void {
		this.router.navigate([...this.orderEditRoute, id.toString()]);
	}

	public toOrderListRoute(type?: ExtendedOrderType | undefined): void {
		if (type) {
			this.router.navigate([...this.ordersRoute, type]);
		} else {
			this.router.navigate([...this.ordersRoute, ExtendedOrderType.All]);
		}
	}

	public toAdminPrintersRoute(): void {
		this.router.navigate(this.getAdminPrintersRoute());
	}

	public toManagerPrintersRoute(): void {
		this.router.navigate(this.getManagerPrintersRoute());
	}

	public toLabelLayoutsRoute(): void {
		this.router.navigate(this.labelLayoutsRoute);
	}

	public toCustomersRoute(): void {
		this.router.navigate(this.adminCustomersRoute);
	}

	public toEmailRoute(): void {
		this.router.navigate(this.emailRoute);
	}

	public toHandheldReadersRoute(): void {
		this.router.navigate(this.adminHandheldReadersRoute);
	}

	public getLabelLayoutsRoute(): string[] {
		return this.labelLayoutsRoute;
	}

	public getAdminUsersRoute(): string[] {
		return this.adminUserListRoute;
	}

	public getAdminEditUserRoute(id: string): string[] {
		return [...this.adminEditUserRoute, id];
	}

	public getAdminAddUserRoute(): string[] {
		return this.adminAddUserRoute;
	}

	public getManagerUsersRoute(): string[] {
		return this.managerUsersRoute;
	}

	public getManagerEditUserRoute(id: string): string[] {
		return [...this.managerEditUserRoute, id];
	}

	public getManagerAddUserRoute(): string[] {
		return this.managerAddUserRoute;
	}

	public toAdminUsersRoute(): void {
		this.router.navigate(this.adminUserListRoute);
	}

	public toAdminUsersListRoute(): void {
		this.router.navigate(this.adminUserListRoute);
	}

	public toManagerUsersRoute(): void {
		this.router.navigate(this.managerUsersRoute);
	}

	public getOrderCreatePrintJobRoute(id: number): string[] {
		return [...this.orderCreatePrintJobRoute, id.toString()];
	}

	public getTiersListRoute(): string[] {
		return this.tiersListRoute;
	}

	public getEmailHistoryRoute(): string[] {
		return [...this.emailHistoryRoute];
	}

	public getInventoryUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Inventory`;
	}

	public getCalculateReorderUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Inventory/CalculateReorder`;
	}

	public getScanSessionsUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/ScanSessions/Index`;
	}

	public getOrdersUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Orders`;
	}

	public getPrintingUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/PrintJobs/Index`;
	}

	public getAdminPrintersUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Printers/OwnerPrintersList`;
	}

	public getAdminTrialsUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Vendor/TrialsList`;
	}

	public getAdminInternalProductsUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/ProductOwner/List`;
	}

	public getManagerPrintersUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Printers/AdminPrintersList`;
	}

	public getManagerUsersUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Users/AdminUsersList`;
	}

	public getProductsUrl(vendorId: number): string | null {
		if (environment.name == EnvironmentName.Local || environment.name == EnvironmentName.Dev) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Products`;
	}

	public getProductUrl(vendorId: number, productId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Products#/edit/${productId}`;
	}

	public getLocationsUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Location/List`;
	}

	public getSettingsUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Menu/Settings`;
	}

	public getLabelLayoutsUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/ZPLDesigner/List`;
	}

	public getLabelLayoutEditUrl(vendorId: number, id: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/ZPLDesigner/Designer?Id=${id}`;
	}

	public getReadersUrl(vendorId: number, isOwnerFacing: boolean): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/FixedReaders/List?isOwnerFacing=${isOwnerFacing}`;
	}

	public getBillingPaymentUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Billing/Billing`;
	}

	public getResourcesUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/Resources/List`;
	}

	public getIdentetyServerUrl(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/IdentityServer/List`;
	}

	public getLocationFramework(vendorId: number): string | null {
		if (!environment.oldSiteBaseAddress) {
			return null;
		}
		return `${environment.oldSiteBaseAddress}/${vendorId}/LocationFrameworks/List`;
	}

	public getForbiddenRoute(): string {
		return this.forbiddenRoute;
	}

	public toDefaultPage() {
		this.router.navigate(['']);
	}

	public getOrderImportRoute(type: string): string[] {
		return [...this.orderImportRoute, type];
	}

	public toOrderImportRoute(type: string | undefined): void {
		this.router.navigate(this.getOrderImportRoute(type ?? ExtendedOrderType.All));
	}

	public goToPreviousPage() {
		this.location.back();
	}
}

export enum OrderType {
	Purchase = 'Purchase',
	Sales = 'Sales',
	Transfer = 'Transfer',
}

export enum ExtendedOrderType {
	All = 'all',
	Purchase = 'purchase',
	Sales = 'sales',
	Transfer = 'transfer',
}

export const displayOrderType: Record<string, string> = {
	purchase: 'Purchase',
	sales: 'Sales',
	transfer: 'Transfer',
};

export const orderTypeValues: Record<ExtendedOrderType, OrderType | undefined> = {
	[ExtendedOrderType.All]: undefined,
	[ExtendedOrderType.Purchase]: OrderType.Purchase,
	[ExtendedOrderType.Sales]: OrderType.Sales,
	[ExtendedOrderType.Transfer]: OrderType.Transfer,
};

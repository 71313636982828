import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '@environments/environment';
import { BaseNotificationService } from '@core/abstractions';

@Injectable({
	providedIn: 'root',
})
export class PortalStationNotificationService extends BaseNotificationService {
	private readonly linkedEventName = 'Linked';
	private readonly addedEventName = 'Added';
	private readonly removedEventName = 'Removed';
	private readonly displayNameChangedName = 'DisplayNameChanged';

	constructor(oidcSecurityService: OidcSecurityService) {
		super(environment.portalBaseAddress + '/signal/v1/portal/station', oidcSecurityService);
	}

	public addStationLinkedListener = (callBackOwner: Object, callback: (clientId: string) => void) => {
		this.addListener(callBackOwner, this.linkedEventName, (clientId: string) => callback(clientId));
	};

	public addStationAddedListener = (callBackOwner: Object, callback: (clientId: string) => void) => {
		this.addListener(callBackOwner, this.addedEventName, (clientId: string) => callback(clientId));
	};

	public addDisplayNameChangedListener = (
		callBackOwner: Object,
		callback: (clientId: string, displayName: string) => void,
	) => {
		this.addListener(callBackOwner, this.displayNameChangedName, (clientId: string, displayName: string) =>
			callback(clientId, displayName),
		);
	};

	public addStationRemovedListener = (callBackOwner: Object, callback: (clientId: string) => void) => {
		this.addListener(callBackOwner, this.removedEventName, (clientId: string) => callback(clientId));
	};
}

import { TreeProductSource, TreeProductStatus } from '@products/enums';

//new V2
export interface ITreeProductModel {
	id: number;
	customerId: number;
	status: TreeProductStatus;
	title: string;
	brand: string;
	variantsCount: number;
	source: TreeProductSource[];
}

export class TreeProductViewModel {
	public id: number;
	public customerId: number;
	public title: string;
	public brand: string;
	public variantsCount: number;
	public status: TreeProductStatus;
	public source: TreeProductSource[];
	public thumbnailUrl?: string;

	constructor(
		id: number,
		customerId: number,
		title: string,
		brand: string,
		variantsCount: number,
		status: TreeProductStatus,
		source: TreeProductSource[],
		thumbnailUrl?: string,
	) {
		this.id = id;
		this.customerId = customerId;
		this.title = title;
		this.brand = brand;
		this.variantsCount = variantsCount;
		this.status = status;
		this.source = source;
		this.thumbnailUrl = thumbnailUrl;
	}
}

//
//
//
//
//
//
//
//
//
//old models, temporary needed for product edit and inventory components
export interface IProductViewModel {
	id: number;
	title: string;
	sku: string;
	brand: string;
	status: string;
	source: string;
	variants: IVariantViewModel[];
}

export interface IVariantViewModel {
	id?: number;
	imgSrc?: string;
	sku?: string;
	price?: number;
	tagsCount?: number;
	isActive?: boolean;
	options: string[];
	items: IProductItemsCount[];
}

export interface IProductItemsCount {
	count: number;
	location: string;
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizedLayoutComponent, NotFoundComponent } from '@layout/components';
import { AdministratorAuthGuard, ManagerAuthGuard } from '@shared/services';
import { AuthInterceptor, AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

const routes: Routes = [
	{
		path: '',
		component: AuthorizedLayoutComponent,
		children: [
			{
				path: '',
				data: { title: 'Welcome' },
				loadChildren: () => import('./welcome/welcome.module').then(m => m.default),
			},
			{
				path: 'printing',
				data: { title: 'Printing V2' },
				loadChildren: () => import('./printing/printing.module').then(m => m.default),
			},
			{
				path: 'inventory',
				data: { title: 'Inventory' },
				loadChildren: () => import('./inventory/inventory.module').then(m => m.default),
			},
			{
				path: 'scan-sessions',
				data: { title: 'Scan Sessions' },
				loadChildren: () => import('./scan-sessions/scan-sessions.module').then(m => m.default),
			},
			{
				path: 'orders',
				data: { title: 'Orders' },
				loadChildren: () => import('./orders/orders.module').then(m => m.default),
			},
			{
				path: 'products',
				data: { title: 'Products' },
				loadChildren: () => import('./products/products.module').then(m => m.default),
			},
			{
				path: 'locations',
				data: { title: 'Locations' },
				loadChildren: () => import('./locations/locations.module').then(m => m.default),
			},
			{
				path: 'settings',
				data: { title: 'Settings' },
				loadChildren: () => import('./settings/settings.module').then(m => m.default),
			},
			{
				path: 'context',
				loadChildren: () => import('./context-importer/context-importer.module').then(m => m.default),
			},
			{
				path: 'handheld-readers',
				loadChildren: () => import('./handheld-readers/handheld-readers.module').then(m => m.default),
			},
			{
				path: 'admin',
				loadChildren: () => import('./admin/admin.module').then(m => m.default),
				canActivate: [AdministratorAuthGuard],
			},
			{
				path: 'manager',
				loadChildren: () => import('./manager/manager.module').then(m => m.default),
				canActivate: [ManagerAuthGuard],
			},
		],
		canActivateChild: [AutoLoginPartialRoutesGuard],
	},
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.module').then(m => m.default),
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
	providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
})
export class AppRoutingModule {}

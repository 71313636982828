import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ILabelLayoutModel, ILabelLayoutFilter } from '@printing/models';
import { Dpi, LabelLayoutOrderField } from '@shared/enums';
import { OffsetPagination, OffsetCollection, IBaseFilter } from '@shared/models';
import { ChunkService } from '@shared/services';
import { ICreateLabelLayoutModel, IUpdateLabelLayoutModel } from '@core/models';

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	private portalUrl: string;

	constructor(
		private chunkService: ChunkService,
		private http: HttpClient,
	) {
		this.portalUrl = environment.portalBaseAddress;
	}

	public getDetails(id: number): Observable<ILabelLayoutModel> {
		const url = this.portalUrl + '/api/v1/printing/layout/' + id;
		return this.http.get<ILabelLayoutModel>(url);
	}

	public getLayouts(
		filter: ILabelLayoutFilter,
		pager: OffsetPagination,
	): Observable<OffsetCollection<ILabelLayoutModel>> {
		const url = `${this.portalUrl}/api/v1/printing/layout`;
		return this.chunkService.get<ILabelLayoutModel, ILabelLayoutFilter>(pager, filter, url);
	}

	public getAllLayouts(filter: ILabelLayoutFilter): Observable<ILabelLayoutModel[]> {
		const url = `${this.portalUrl}/api/v1/printing/layout`;
		return this.chunkService.getAll<ILabelLayoutModel, ILabelLayoutFilter>(filter, url);
	}

	public create(model: ICreateLabelLayoutModel): Observable<any> {
		const url = this.portalUrl + '/api/v1/printing/layout';
		return this.http.post(url, model);
	}

	public update(id: number, model: IUpdateLabelLayoutModel): Observable<any> {
		const url = this.portalUrl + '/api/v1/printing/layout/' + id;
		return this.http.put(url, model);
	}

	public delete(id: number, customerId: number): Observable<any> {
		const url = this.portalUrl + '/api/v1/customers/' + customerId + '/printing/layout/' + id;
		return this.http.delete(url);
	}
}

interface ILabelLayoutRequest extends IBaseFilter<LabelLayoutOrderField> {
	vendorId: number;
	dpi?: Dpi;
}

export enum TreeProductStatus {
	Active = 'Active',
	Archived = 'Archived',
	Draft = 'Draft',
}

export const treeProductStatusDisplayValue: Record<string, string> = {
	Active: 'Active',
	Archived: 'Archived',
	Draft: 'Draft',
};

import { EnvironmentName } from '@core/enums/environment-name.enum';

export const environment = {
	production: false,
	identityBaseAddress: 'https://dev-identity.simplerfid.com',
	portalBaseAddress: 'https://dev-portal-service.simplerfid.com',
	printServiceBaseAddress: 'https://dev-print-service.simplerfid.com',
	emailService: {
		baseAddress: 'https://dev-email-service.simplerfid.com',
		apiVersion: 'v1',
	},
	ngrxDevtoolsOn: true,
	searchDebounceTimeMilliseconds: 500,
	errorThrottleTimeMilliseconds: 10000,
	resourceBaseAddress: 'https://devsimplerfid.blob.core.windows.net',
	oldSiteBaseAddress: 'https://webdev.simplerfid.com',
	sirnalRLogLevel: 3, // TODO: Change to SignalR (typo)
	sentry: {
		dsn: 'https://7769ce721eeee0b5773ef352e31950d6@o4506074038992896.ingest.sentry.io/4506076482109440',
		environment: 'development',
		tracePropagationTargets: [
			'dev-identity.simplerfid.com',
			'dev-portal-service.simplerfid.com',
			'dev-print-service.simplerfid.com',
		],
	},
	googleAnalytics: 'G-K7ZPD79V2V',
	printerImageBaseUrl: '/assets/images/printers/',
	name: EnvironmentName.Dev,
};

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationLightModel } from '@shared/models';
import { FilterService } from 'primeng/api';
import { BehaviorSubject, combineLatest, filter, shareReplay, switchMap } from 'rxjs';
import { BaseLocationFilter } from '@core/abstractions';
import { ILocationRequest, LocationOrderField } from '@core/models';
import { LocationType, OrderDirection } from '@shared/enums';

@UntilDestroy()
@Component({
	selector: 'location-filter',
	templateUrl: './location-filter.component.html',
	styleUrl: './location-filter.component.scss',
})
export class LocationFilterComponent extends BaseLocationFilter {
	public selectedLocation: LocationLightModel | undefined = undefined;

	@Output() locationKeyChange = new EventEmitter<string>();

	public loading$ = new BehaviorSubject<boolean>(false);
	public eLocationType = LocationType;
	protected _vendorId$ = new BehaviorSubject<number | undefined>(undefined);

	@Input() set vendorId(val: number | undefined | null) {
		if (val) {
			this._vendorId$.next(val);
			this.loading$.next(true);
		}
	}

	@Input() showClear: boolean = false;

	@Input() disabled: boolean = false;
	@Input() label?: string;

	constructor(
		filterService: FilterService,
		private locationService: LocationService,
	) {
		super(filterService);

		const locationSource$ = this._vendorId$.pipe(
			filter(vendorId => vendorId != undefined),
			switchMap(vendorId => {
				if (vendorId != undefined) {
					const request: ILocationRequest = {
						customerIds: [vendorId],
						orderBy: LocationOrderField.CustomerId,
						orderDirection: OrderDirection.Ascending,
					};
					return this.locationService.getAll(request);
				} else {
					return [];
				}
			}),
			shareReplay(),
		);

		combineLatest({
			locations: locationSource$,
			selectedVendor: this._vendorId$,
		})
			.pipe(untilDestroyed(this))
			.subscribe(resp => {
				this._groupedLocations = this.groupLocation(resp.locations, resp.selectedVendor);
				this.loading$.next(false);
			});
	}

	public onClear() {
		this.locationKeyChange.emit(undefined);
	}

	public onLocationSelect(location: LocationLightModel) {
		this.locationKeyChange.emit(location.locationKey);
	}
}

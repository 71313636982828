import { Injectable } from '@angular/core';
import { BaseNotificationService } from '@core/abstractions';
import { environment } from '@environments/environment';
import { State } from '@shared/enums';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
	providedIn: 'root',
})
export class PrintStationNotificationService extends BaseNotificationService {
	private readonly stateChangedEventName = 'StateChanged';
	private readonly registeredEventName = 'Registered';

	constructor(oidcSecurityService: OidcSecurityService) {
		super(environment.printServiceBaseAddress + '/signal/v1/printing/stations', oidcSecurityService);
	}

	public addStationStateChangedListener = (
		callBackOwner: Object,
		callback: (clientId: string, state: State) => void,
	) => {
		this.addListener(callBackOwner, this.stateChangedEventName, (clientId: string, state: State) =>
			callback(clientId, state),
		);
	};

	public addStationRegisteredListener = (callBackOwner: Object, callback: (clientId: string) => void) => {
		this.addListener(callBackOwner, this.registeredEventName, (clientId: string) => callback(clientId));
	};
}
